import { LinkButton } from '@adsk/alloy-react-button';
import {
  LogoAutodeskAccIcon,
  LogoAutodeskBim360Icon,
  StarFilledIcon,
  StarIcon,
  TriangleDownIcon,
} from '@adsk/alloy-react-icon';
import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { memo, useMemo, useState } from 'react';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import { Popover } from '~/shared/components/base/popover';
import { Skeleton } from '~/shared/components/base/skeleton';
import { Tooltip } from '~/shared/components/base/tooltip';
import { SearchInput } from '~/shared/components/forms/search-input';
import { Queries } from '~/shared/hooks/queries';

export const ProjectPicker = memo(function _ProjectPicker() {
  const $env = Oasis.Env.useStore();
  const $session = Oasis.Session.useStore();
  const projectMatch = useMatch('/projects/:id/*');
  const fileMatch = useMatch('/projects/:projectId/files/:fileId');
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');

  const { data, isLoading } = Queries.Projects.useListAllProjects();

  const activeProject = useMemo(() => {
    if (data && projectMatch?.params.id) {
      return data.find(project => project.id === projectMatch.params.id);
    }
  }, [data, projectMatch]);

  const projects = useMemo(() => {
    const items = data || [];
    const q = query.toLowerCase();

    return items
      .filter(item => item.name.toLowerCase().includes(q))
      .map(item => ({ ...item, starred: $session.favoriteProjectIds.includes(item.id) }))
      .sort((a, b) => Number(b.starred) - Number(a.starred));
  }, [data, query, $session.favoriteProjectIds]);

  if (!projectMatch) {
    return null;
  }

  if (isLoading) {
    return <ProjectPickerSkeleton />;
  }

  if (projects.length === 0 && !query) {
    return null;
  }

  return (
    <div className="flex items-center">
      {projectMatch && <div className="w-[1px] h-8 bg-charcoal-200 mx-5" />}

      {($env.isVrWorkshop || fileMatch) && activeProject && <ProjectContent {...activeProject} disabled />}

      {!$env.isVrWorkshop && !fileMatch && (
        <Popover open={open} onOpenChange={setOpen}>
          <Popover.Trigger className="h-full py-1 hover:opacity-80">
            {activeProject ? <ProjectContent {...activeProject} isTrigger /> : <ProjectPlaceholder />}
          </Popover.Trigger>

          <Popover.Content side="bottom" sideOffset={8} align="start" alignOffset={-12} className="w-[20rem] z-30">
            <div className="pt-3 px-3 bg-white">
              <SearchInput value={query} onTextChange={setQuery} autoFocus={open} />

              {query && (
                <p className="font-bold text-caption mt-2 text-right text-charcoal-600">{projects.length} Results</p>
              )}
            </div>

            <section className="w-full max-w-[20rem]">
              <div className="px-3">
                <p className="text-heading-4 mt-4 pb-2 border-b border-charcoal-200">Projects</p>
              </div>

              <div className="max-h-[20rem] overflow-hidden">
                {projects.length > 0 && (
                  <Virtuoso
                    role="list"
                    data={projects}
                    style={{ height: `${Math.min(projects.length * 60, 320) / 16}rem` }}
                    itemContent={(_index, project) => {
                      const isActive = projectMatch?.params.id === project.id;
                      const trailingPath = projectMatch.params['*'] ? `/${projectMatch.params['*']}` : '';

                      return (
                        <div
                          role="listitem"
                          key={project.id}
                          className={clsx(
                            'flex items-center w-full h-15',
                            isActive ? 'bg-blue-500 hover:bg-blue-500 text-white' : 'hover:bg-charcoal-50',
                            project.platform === 'bim360' && 'pointer-events-none text-charcoal-500'
                          )}
                        >
                          <Link to={`/projects/${project.id}${trailingPath}`} className="flex w-full py-3 pl-3">
                            <ProjectContent {...project} isActive={isActive} />
                          </Link>

                          <button
                            type="button"
                            className={clsx(
                              'px-2 mx-1',
                              project.starred ? 'text-yellorange-500  ' : 'opacity-10 hover:opacity-75'
                            )}
                            onClick={() => Oasis.Users.toggleFavoriteProject(project.id)}
                          >
                            {project.starred ? <StarFilledIcon /> : <StarIcon />}
                          </button>
                        </div>
                      );
                    }}
                  />
                )}
              </div>

              {projects.length === 0 && (
                <p className="text-center my-10 text-charcoal-500">
                  {query ? 'No projects match your search.' : 'Not a member of any projects.'}
                </p>
              )}
            </section>

            <div className="border-t border-charcoal-200 p-3">
              <LinkButton
                onClick={() => {
                  navigate('/projects');
                }}
                className="text-blue-500 font-medium"
              >
                View all projects
              </LinkButton>
            </div>
          </Popover.Content>
        </Popover>
      )}
    </div>
  );
});

interface ProjectContentProps {
  name: string;
  accountName: string;
  platform: string;
  isTrigger?: boolean;
  isActive?: boolean;
  disabled?: boolean;
}

function ProjectContent({ name, accountName, platform, isTrigger, isActive, disabled }: ProjectContentProps) {
  return (
    <div className="flex items-center group w-full">
      <div className="max-w-[14rem]">
        <Tooltip content={name} placement="bottom">
          <p
            className={clsx(
              'truncate py-1.5',
              disabled && 'text-charcoal-900 font-bold',
              isTrigger && 'font-bold text-charcoal-900'
            )}
          >
            {name}
          </p>
        </Tooltip>
        {!isTrigger && !disabled && <p className="text-caption opacity-60">{accountName}</p>}
      </div>

      <div
        className={clsx(
          'flex items-center ml-auto pl-4',
          isTrigger || disabled ? 'text-charcoal-700' : isActive ? 'text-white' : 'text-blue-logo'
        )}
      >
        {
          {
            acc: <LogoAutodeskAccIcon className="w-4 h-4" />,
            bim360: <LogoAutodeskBim360Icon className="w-4 h-4 text-charcoal-500" />,
          }[platform]
        }

        {isTrigger && <TriangleDownIcon className="ml-2" />}
      </div>
    </div>
  );
}

function ProjectPlaceholder() {
  return (
    <div className="flex items-center group">
      <div className="flex-1 max-w-[16rem]">
        <p className="truncate font-bold text-charcoal-900">Choose a project</p>
      </div>

      <div className={clsx('flex items-center pl-4 text-charcoal-700')}>
        <TriangleDownIcon className="ml-2" />
      </div>
    </div>
  );
}

function ProjectPickerSkeleton() {
  return (
    <div className="flex items-center">
      <div className="w-[1px] h-8 bg-charcoal-200 mx-5" />
      <div className="flex items-center w-[26.25rem]">
        <Skeleton>
          <Skeleton className="ml-2">
            <Skeleton shape="circle" size="sm" className="ml-3" />
          </Skeleton>
        </Skeleton>
      </div>
    </div>
  );
}

import Avatar from '@adsk/alloy-react-avatar';
import { XIcon } from '@adsk/alloy-react-icon';
import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { OasisErrorState } from '~/shared/components/base/oasis-error-state';
import { useWorkshopAudience } from '~/shared/hooks/use-workshop-audience';
import { ViewerSettingsPanel } from './viewer-settings-panel';
import { MicIcon } from './waiting-room-voice/icons/mic';
import { MicOffIcon } from './waiting-room-voice/icons/mic-off';

interface Props {
  projectId: string;
  workshopId: string;
  activePanel?: string;
  onClose?: () => void;
}

export function ViewerPanels(props: Props) {
  switch (props.activePanel) {
    case 'users':
      return <UsersPanel {...props} />;
    case 'settings':
      return <ViewerSettingsPanel {...props} />;
  }

  return null;
}

function UsersPanel(props: Props) {
  const $voice = Oasis.Voice.useStore();
  const $workshopAudience = useWorkshopAudience(props.workshopId);

  return (
    <aside className="flex flex-col shadow-high bg-white z-10 w-80 h-full">
      <header className="flex items-center justify-between h-12 px-4">
        <h3 className="text-heading-1">People</h3>
        <button onClick={props.onClose}>
          <XIcon />
        </button>
      </header>
      <div className="flex flex-1 overflow-auto">
        {$workshopAudience.allUsers.length < 2 ? (
          <div className="flex flex-1 items-center justify-center">
            <OasisErrorState
              code="NOT_FOUND"
              title="No workshop users"
              description="When other users are present you can view them and control their audio here."
            />
          </div>
        ) : (
          <ul className="w-full">
            {$workshopAudience.allUsers.map(user => {
              const voiceUserDetails = $voice.mutedUsers.get(user.userId);

              const isMe =
                user.additionalDetails.userId === $workshopAudience.currentUser?.additionalDetails.userId &&
                user.additionalDetails.device !== 'VR';
              const isHost =
                user.additionalDetails.userId === $workshopAudience.hostUser?.additionalDetails.userId &&
                user.additionalDetails.device === 'VR';

              const name =
                user.additionalDetails.userName && user.additionalDetails.userLastName
                  ? `${user.additionalDetails.userName} ${user.additionalDetails.userLastName}`
                  : 'Anonymous';

              return (
                <li
                  key={user.clientId}
                  className={clsx('flex items-center py-3 px-4', isMe && 'border-y border-charcoal-200')}
                >
                  <div className={clsx('rounded-full border-4', isHost ? 'border-blue-300' : 'border-transparent')}>
                    <Avatar
                      name={name}
                      id={user.additionalDetails.userId}
                      imageUrl={user.additionalDetails.userImage}
                      size="medium"
                    />
                  </div>
                  <div className="ml-2">
                    <p className="font-bold">{isMe ? `${name} (Me)` : name}</p>
                    <p className="text-body-sm text-charcoal-500">
                      {isHost ? (
                        'VR host (following)'
                      ) : (
                        <>
                          {user.additionalDetails.device === 'VR' && 'VR collaborator'}
                          {user.additionalDetails.device === 'DESKTOP' && 'Desktop viewer'}
                          {user.additionalDetails.device === 'WEB' && 'Web viewer'}
                          {/* This should be foltered anyway */}
                          {user.additionalDetails.device === 'WEB-VR' && 'Console'}
                        </>
                      )}
                    </p>
                  </div>

                  {typeof voiceUserDetails === 'boolean' && (
                    <div className="flex items-center ml-auto space-x-4 text-charcoal-500">
                      <button
                        className={clsx(voiceUserDetails ? 'text-red-500 hover:text-red-700' : 'hover:text-blue-500')}
                        // onClick={() => voiceUser}
                      >
                        {voiceUserDetails ? <MicOffIcon className="w-6 h-6" /> : <MicIcon className="w-6 h-6" />}
                      </button>
                    </div>
                  )}

                  {isMe && (
                    <div className="flex items-center ml-auto space-x-4 text-charcoal-500">
                      <button
                        className={clsx($voice.muted ? 'text-red-500 hover:text-red-700' : 'hover:text-blue-500')}
                        onClick={() => Oasis.Voice.toggleMuted()}
                      >
                        {$voice.muted ? <MicOffIcon className="w-6 h-6" /> : <MicIcon className="w-6 h-6" />}
                      </button>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </aside>
  );
}

import { GearIcon, PeopleIcon } from '@adsk/alloy-react-icon';
import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { LargeModelViewer } from '~/features/files/components/large-model-viewer';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { Queries } from '~/shared/hooks/queries';
import type { ExtensionLoadingState } from '~/shared/hooks/use-collaboration-extension';
import { useWorkshopAudience } from '~/shared/hooks/use-workshop-audience';
import { FollowingBorder } from './following-border';
import { ViewerPanels } from './viewer-panels';

interface Props {
  projectId: string;
  workshopId: string;
  workshop: ReturnType<typeof Queries.Workshops.useFindWorkshopById>;
}

export function ViewingRoom({ workshop, ...props }: Props) {
  const [activePanel, setActivePanel] = useState<string | undefined>();
  const [collabExtension, setCollabExtension] = useState<any>();
  const activeModelVersionId = Queries.Workshops.useGetActiveModelVersionId(props.workshopId);
  const $workshopAudience = useWorkshopAudience(props.workshopId);
  const hostId = workshop.data?.userId;

  useEffect(() => {
    let handleDisconnect = () => {
      Oasis.Voice.disconnect();
    };

    Oasis.Voice.connectToWorkshop({ workshopId: props.workshopId }).then(result => {
      if (result.ok) {
        handleDisconnect = result.value.disconnectFromWorkshop;
      }
    });

    return handleDisconnect;
  }, [props.workshopId]);

  useEffect(() => {
    if (collabExtension && $workshopAudience.hostUser) {
      collabExtension.startFollow($workshopAudience.hostUser?.clientId);
      return () => collabExtension.startFollow(null);
    }
  }, [hostId, collabExtension, $workshopAudience.hostUser]);

  const onClose = useCallback(() => {
    setActivePanel(undefined);
  }, [setActivePanel]);

  const onCollabExtStateChange = useCallback(
    (state: ExtensionLoadingState, extension?: any) => {
      switch (state) {
        case 'INITIAL':
        case 'LOADING':
          break;
        case 'LOADED':
          setCollabExtension(extension);
          break;
        case 'FAILED':
          NotificationManager.push({
            status: 'error',
            content: 'Collaboration extension failed to load',
          });
          break;
        default:
          state satisfies never;
      }
    },
    [setCollabExtension]
  );

  if (workshop.isLoading) {
    return null;
  }

  return (
    <section className="flex flex-1">
      <nav className="w-12 h-full bg-charcoal-50 border-r border-charcoal-100">
        {[
          [
            // { name: 'sheets', icon: <SheetsIcon />, disabled: true },
            // { name: 'levels', icon: <LevelsIcon />, disabled: true },
            // { name: 'issues', icon: <CheckmarkCircleIcon />, disabled: true },
            // { name: 'view-points', icon: <BookmarkIcon />, disabled: true },
            // { name: 'model-browser', icon: <ModelBrowserIcon />, disabled: true },
          ],
          [
            // { name: 'model-browser', icon: <ModelBrowserIcon />, disabled: true }, // move back up eventually
            // { name: 'components', icon: <ComponentsIcon />, disabled: true },
            { name: 'users', icon: <PeopleIcon />, disabled: false },
            // { name: 'share', icon: <ShareIcon />, disabled: true },
            { name: 'settings', icon: <GearIcon />, disabled: false },
          ],
        ].map((tools, index) => (
          <Fragment key={index}>
            {/* {index > 0 && <hr className="border-charcoal-100 w-6 my-6 mx-auto" />} */}
            <ul>
              {tools.map(tool => (
                <li key={tool.name}>
                  <button
                    disabled={tool.disabled}
                    className={clsx(
                      'flex items-center justify-center w-full h-11 border-x-2',
                      activePanel === tool.name
                        ? 'bg-white text-blue-500 border-l-blue-500 border-r-transparent'
                        : 'text-charcoal-700 border-transparent'
                    )}
                    onClick={() => setActivePanel(tool.name)}
                  >
                    {tool.icon}
                  </button>
                </li>
              ))}
            </ul>
          </Fragment>
        ))}
      </nav>
      <div className="adsk-web-viewer relative flex flex-1">
        {activeModelVersionId.data && (
          <LargeModelViewer
            versionId={activeModelVersionId.data}
            onCollabExtStateChange={onCollabExtStateChange}
            isCollaborativeWebViewer
          />
        )}

        <div className="absolute top-0 left-0 bottom-0 flex">
          {activePanel && (
            <ViewerPanels
              projectId={props.projectId}
              workshopId={props.workshopId}
              activePanel={activePanel}
              onClose={onClose}
            />
          )}
        </div>

        {hostId && <FollowingBorder />}
      </div>
    </section>
  );
}

import Button from '@adsk/alloy-react-button';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import StepIndicator from '@adsk/alloy-react-step-indicator';
import { Oasis } from '@oasis/sdk';
import { useEffect, useState } from 'react';
import { ExternalLinkIcon } from '~/shared/components/icons/external-link-icon';
import { Queries } from '~/shared/hooks/queries';
import { useTemporaryBranding } from '~/shared/hooks/use-temporary-branding';
import { landingUrl } from '~/shared/utils/const.asset-urls';
import { ConsentForm } from './consent-form';

const HUBS_ALPHA_URL = 'https://stg-manage.autodesk.com/legacy-provisioning/app-provisioning?product=bimacc';
const HUBS_PROD_URL = 'https://manage.autodesk.com/legacy-provisioning/app-provisioning?product=bimacc';
const PROJECTS_ALPHA_URL = 'https://acc-staging.autodesk.com/projects';
const PROJECTS_PROD_URL = 'https://acc.autodesk.com/projects';

const STEPS = [
  { label: 'Autodesk Account' },
  {
    label: (
      <>
        ACC
        <br /> Project
      </>
    ),
  },
  { label: 'Profile & Disclosure' },
];

export function Prerequisites() {
  const $env = Oasis.Env.useStore();
  const branding = useTemporaryBranding();

  const [activeIndex, setActiveIndex] = useState(() => _getActiveIndex());
  const [clickedLinks, setClickedLinks] = useState<('hubs' | 'projects')[]>([]);

  const hubsIsActive = activeIndex === 0;
  const projectsIsActive = activeIndex === 1;

  const hubs = Queries.Hubs.useList({
    enabled: hubsIsActive,
    refetchInterval: hubsIsActive ? 5000 : undefined,
    refetchOnWindowFocus: hubsIsActive,
    staleTime: 0,
  });
  const hasHub = !!hubs?.data && hubs.data.length > 0;
  const projects = Queries.Projects.useListAllProjects({
    opts: {
      enabled: projectsIsActive,
      refetchInterval: projectsIsActive ? 5000 : undefined,
      refetchOnWindowFocus: projectsIsActive,
      staleTime: 0,
    },
  });
  const hasProject = projects.data && projects.data.length > 0;

  useEffect(() => {
    if (hasHub && activeIndex === 0) {
      Oasis.Session.removeMissingPrerequisite('hub');
    }
    if (hasProject && activeIndex === 1) {
      Oasis.Session.removeMissingPrerequisite('project');
    }

    setActiveIndex(_getActiveIndex());
  }, [hasHub, hasProject, activeIndex]);

  return (
    <main
      className="min-w-screen min-h-screen flex bg-cover p-10 overflow-auto"
      style={{ backgroundImage: `url(${landingUrl})` }}
    >
      <div className="min-h-[634px] m-auto">
        <section className="w-full max-w-md bg-white shadow-high rounded-md">
          <header className="py-5 border-b border-charcoal-100 mb-4">
            <h1 className="text-center text-heading-2">Welcome to {branding.productName}</h1>
          </header>

          <section>
            <StepIndicator steps={STEPS} activeIndex={activeIndex} labelPosition="bottom" />

            <ol className="mt-6">
              {/* Account */}
              {activeIndex === 0 && (
                <li>
                  <div className="text-center px-6">
                    <p className="text-left">
                      Your Autodesk account is not currently associated with any supported Autodesk Construction
                      Cloud(ACC) accounts. To get started with Autodesk Workshop XR, you&apos;ll need access to a{' '}
                      <span className="underline">US-hosted ACC account</span>.{' '}
                      <span className="font-bold">
                        Click the link below to create a new ACC account, and return to this page to continue.
                      </span>
                    </p>
                    <p className="mt-4">
                      <a
                        href={$env.releaseChannel === 'alpha' ? HUBS_ALPHA_URL : HUBS_PROD_URL}
                        target="_blank"
                        className="inline-flex items-center text-blue-700 hover:underline"
                        onClick={() => {
                          setClickedLinks([...clickedLinks, 'hubs']);
                        }}
                        rel="noreferrer"
                      >
                        Autodesk account <ExternalLinkIcon className="ml-2" />
                      </a>
                    </p>

                    <p className="mt-8">
                      Or, contact your ACC account administrator
                      <br />
                      to request an account.
                    </p>
                    {/* <p className="mt-2">
                          <a
                            href="#"
                            target="_blank"
                            className="inline-flex items-center text-blue-700 hover:underline"
                            rel="noreferrer"
                          >
                            <EnvelopeIcon className="mr-2" /> Request account
                          </a>
                        </p> */}
                  </div>

                  <p className="mt-10 border-t border-charcoal-100 flex justify-between gap-5 py-4 px-6">
                    {clickedLinks.includes('hubs') && (
                      <p className="flex items-center">
                        <ProgressRing size="xsmall" className="mr-2" />
                        <span className="text-charcoal-500">Checking for account...</span>
                      </p>
                    )}

                    <Button
                      as="a"
                      href="https://www.autodesk.com/support/account/manage/use/explore"
                      target="_blank"
                      variant="secondary"
                      className="ml-auto"
                    >
                      Account help
                    </Button>
                  </p>
                </li>
              )}

              {/* Project */}
              {activeIndex === 1 && (
                <li>
                  <div className="text-center px-6">
                    <p>
                      You&apos;ll need to create or become a member of an Autodesk Construction Cloud (ACC) project.{' '}
                      <span className="font-bold">
                        Click the link below to go to the ACC project page. Once you&apos;ve joined or created a
                        project, please return to this page to continue.
                      </span>
                    </p>

                    <p className="mt-4">
                      <a
                        href={$env.releaseChannel === 'alpha' ? PROJECTS_ALPHA_URL : PROJECTS_PROD_URL}
                        target="_blank"
                        className="inline-flex items-center text-blue-700 hover:underline"
                        onClick={() => {
                          setClickedLinks([...clickedLinks, 'projects']);
                        }}
                        rel="noreferrer"
                      >
                        ACC Projects <ExternalLinkIcon className="ml-2" />
                      </a>
                    </p>

                    <p className="mt-8">
                      Or, contact your ACC account administrator
                      <br />
                      to request project access.
                    </p>
                    {/* <p className="mt-2">
                      <a
                        href="#"
                        target="_blank"
                        className="inline-flex items-center text-blue-700 hover:underline"
                        rel="noreferrer"
                      >
                        <EnvelopeIcon className="mr-2" /> Request project access
                      </a>
                    </p> */}
                  </div>

                  <p className="mt-10 border-t border-charcoal-100 flex justify-between gap-5 py-4 px-6">
                    {clickedLinks.includes('projects') && (
                      <p className="flex items-center">
                        <ProgressRing size="xsmall" className="mr-2" />
                        <span className="text-charcoal-500">Checking for project...</span>
                      </p>
                    )}

                    <Button
                      as="a"
                      href="https://help.autodesk.com/view/DOCS/ENU/?guid=Create_Manage_Projects"
                      target="_blank"
                      variant="secondary"
                      className="ml-auto"
                    >
                      ACC project help
                    </Button>
                  </p>
                </li>
              )}

              {/* Form */}
              {activeIndex === 2 && (
                <li>
                  <div className="px-6">
                    <p className="mb-6">
                      Please review and acknowledge the disclosure, which contains important information about our
                      services.
                    </p>

                    <ConsentForm />
                  </div>
                </li>
              )}
            </ol>
          </section>
        </section>
      </div>
    </main>
  );
}

function _getActiveIndex(
  missingPrerequisites: ('hub' | 'project' | 'profile')[] = Oasis.Session.store.missingPrerequisites
) {
  let index = 0;

  if (missingPrerequisites.includes('hub')) {
    index = 0;
  } else if (missingPrerequisites.includes('project')) {
    index = 1;
  } else if (missingPrerequisites.includes('profile')) {
    index = 2;
  }

  return index;
}

import Avatar, { AvatarGroup, DefaultAvatar } from '@adsk/alloy-react-avatar';
import Button from '@adsk/alloy-react-button';
import { GearIcon } from '@adsk/alloy-react-icon';
import Toggle from '@adsk/alloy-react-toggle/es/Toggle';
import { Oasis } from '@oasis/sdk';
import { ArrayUtils } from '@oasis/utils';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FadingEllipsis } from '~/shared/components/base/fading-ellipsis';
import { Queries } from '~/shared/hooks/queries';
import { useWorkshopAudience } from '~/shared/hooks/use-workshop-audience';
import type { ViewerPageState } from '../page';
import { ViewerSettingsPanel } from './viewer-settings-panel';
import { MicIcon } from './waiting-room-voice/icons/mic';
import { MicOffIcon } from './waiting-room-voice/icons/mic-off';

interface Props {
  projectId: string;
  workshopId: string;
  workshop: ReturnType<typeof Queries.Workshops.useFindWorkshopById>;
  setViewerPageState: (viewerState: ViewerPageState) => void;
}

export function WaitingRoom({ setViewerPageState, ...props }: Props) {
  const $voice = Oasis.Voice.useStore();
  const $session = Oasis.Session.useStore();
  const $debug = Oasis.Debug.useStore();
  const $workshopAudience = useWorkshopAudience(props.workshopId);

  const [showSettings, setShowSettings] = useState(false);
  const [userReady, setUserReady] = useState(false);

  const activeModelVersionId = Queries.Workshops.useGetActiveModelVersionId(props.workshopId, {
    refetchInterval: 5000,
  });

  const hasHost = !!$workshopAudience.hostUser;
  const hasActiveModel = !!activeModelVersionId.data;

  const userName = `${$session.user?.firstName} ${$session.user?.lastName}`;
  const waitingState = hasHost ? (hasActiveModel ? 'READY' : 'WAITING_FOR_MODEL') : 'WAITING_FOR_HOST';
  const userImageUrl = $session.user?.images?.sizeX120 && $session.user.images.sizeX120.replace('x120', 'x360');

  const usersList = (
    $workshopAudience.hostUser?.additionalDetails.userId === $session.user?.id
      ? $workshopAudience.viewingUsers
      : [$workshopAudience.hostUser, ...$workshopAudience.viewingUsers]
  ).filter(ArrayUtils.truthy);

  useEffect(() => {
    if ((waitingState === 'READY' || $debug.skipWebViewerWaitingRoom) && userReady) {
      setViewerPageState('READY');
    }
  }, [waitingState, userReady, setViewerPageState, $debug.skipWebViewerWaitingRoom]);

  return (
    <div className="flex-1 flex">
      <section className="bg-white shadow-high m-auto rounded w-full max-w-3xl">
        <div className="flex items-center p-6 gap-10">
          <div className="flex items-center justify-center w-1/2 bg-charcoal-50 rounded p-10 min-h-60">
            <div className="rounded-full border-[8px] border-white p-[10px]">
              <div className="rounded-full border-[5px] border-white">
                <Avatar size="extraExtraLarge" name={userName} imageUrl={userImageUrl} />
              </div>
            </div>
          </div>
          <div className="relative w-1/2">
            {waitingState === 'WAITING_FOR_HOST' && (
              <>
                <h2 className="text-heading-1 mb-1 min-h-8">
                  Please wait for the host
                  <FadingEllipsis />
                </h2>
                <p className="mt-1">You can join this workshop when you&apos;re ready.</p>
              </>
            )}

            {waitingState === 'WAITING_FOR_MODEL' && (
              <h2 className="text-heading-1 mb-1 min-h-8">
                Waiting for model <FadingEllipsis />
              </h2>
            )}

            {waitingState === 'READY' && (
              <h2 className="text-heading-1 mb-1 min-h-8">Host is ready to start the workshop</h2>
            )}

            {usersList.length > 0 ? (
              <AvatarGroup truncation="numeric" variant="compact" max={4} className="mt-4 ml-[6px] min-h-9">
                {usersList.map(user => {
                  const name = `${user.additionalDetails.userName} ${user.additionalDetails.userLastName}`;
                  return <Avatar key={user.clientId} name={name} imageUrl={user.additionalDetails.userImage} />;
                })}
              </AvatarGroup>
            ) : (
              <div className="flex items-center mt-4">
                <DefaultAvatar className="mr-2" />
                <p className="text-charcoal-500">No one else is here</p>
              </div>
            )}

            <hr className="border-t border-charcoal-100 my-6 w-full" />

            <div className="flex items-center justify-between mb-6">
              <label className="text-label-md text-charcoal-700">Display name</label>
              {userName}
            </div>

            <div className="flex items-center justify-between">
              <label className="text-label-md text-charcoal-700">Microphone</label>

              <div className="flex items-center">
                {$voice.muted ? (
                  <MicOffIcon className="w-6 h-6 mr-2 text-charcoal-600" />
                ) : (
                  <MicIcon className="w-6 h-6 mr-2" />
                )}
                <Toggle checked={!$voice.muted} onChange={() => Oasis.Voice.setMuted(!$voice.muted)} />
              </div>
            </div>
          </div>
        </div>

        <footer className="flex items-center py-5 px-6 border-t border-charcoal-200 flex">
          <button onClick={() => setShowSettings(!showSettings)} className="hover:text-blue-700">
            <GearIcon />
          </button>

          <div className="ml-auto flex gap-2">
            {/* @ts-ignore */}
            <Button as={Link} to={`/projects/${props.projectId}`} variant="tertiary">
              Cancel
            </Button>
            <Button variant="primary" onClick={() => setUserReady(true)} loading={userReady}>
              Join workshop
            </Button>
          </div>
        </footer>
      </section>

      {showSettings && <ViewerSettingsPanel onClose={() => setShowSettings(false)} />}
    </div>
  );
}
